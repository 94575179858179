import axios from 'axios';
import getToken  from './getToken';

const API_URL = process.env.REACT_APP_API_URL;

// Set headers with the token
const setHeaders = async () => {
  const token = await getToken();
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

// Global API object
const Api = {
  async get(endpoint, params = {}) {
    await setHeaders();
  
    try {
      const req = await axios.get(`${API_URL}${endpoint}`, { params }); // Pass params here
      return req.status === 202 ? true : req.data;
    } catch (e) {
      if (e?.response?.data?.includes('Bad Gateway')) return;
      return e?.response?.data || 'Something went wrong!';
    }
  },

  async post(endpoint, params) {
    await setHeaders();

    try {
      const req = await axios.post(`${API_URL}${endpoint}`, params);
      return req.status === 202 ? true : req.data;
    } catch (e) {
      if (e?.response?.status === 401) {
        return e.response.data;
      }
      if (typeof e.response?.data === 'string' && e.response.data.includes('Bad Gateway')) return;
      return e?.response?.data || 'Something went wrong!';
    }
  },

  async put(endpoint, params) {
    await setHeaders();

    try {
      const req = await axios.put(`${API_URL}${endpoint}`, params);
      return req.status === 202 ? true : req.data;
    } catch (e) {
      if (e?.response?.status === 401) {
        return e.response.data;
      }
      if (e?.response?.data?.includes('Bad Gateway')) return;
      return e?.response?.data || 'Something went wrong!';
    }
  },

  async patch(endpoint, params) {
    await setHeaders();

    try {
      const req = await axios.patch(`${API_URL}${endpoint}`, params);
      return req.status === 202 ? true : req.data;
    } catch (e) {
      if (e?.response?.status === 401) {
        return e.response.data;
      }
      if (e?.response?.data?.includes('Bad Gateway')) return;
      return e?.response?.data || 'Something went wrong!';
    }
  },

  async delete(endpoint, params) {
    await setHeaders();

    try {
      const req = await axios.delete(`${API_URL}${endpoint}`, { data: params });
      return req.status === 202 ? true : req.data;
    } catch (e) {
      if (e?.response?.status === 401) {
        return e.response.data;
      }
      if (e?.response?.data?.includes('Bad Gateway')) return;
      return e?.response?.data || 'Something went wrong!';
    }
  },
};

export default Api;