import styled from 'styled-components';

export const UserListContainer = styled.div`
  height: 900px; // Set a fixed height for the scrollable area
  width: 100%;
  overflow-y: auto; // Enable vertical scrolling
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

export const UserCard = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 4px;
  width: 100%;
  background: ${({ $active }) => ($active ? '#d3e3ff' : 'white')};
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: black;
  border-bottom: 1px solid #e0e0e0;

  &:hover {
    background-color: ${({ $active }) => ($active ? '#aacbff' : '#f5f5f5')};
  }
`;

export const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const UserName = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #333;
`;

export const LastLogin = styled.div`
  font-size: 12px;
  color: gray;
  margin-top: 4px;
`;