// ArtificialIntelligence.js
import React, { useState, useEffect, useRef } from 'react';
import {
  ChatContainer,
  ChatBox,
  ChatMessage,
  ChatInputForm,
  Input,
  SendButton,
  ErrorMessage,
} from './styles';
import Api from '../../../utils/api';

export const ArtificialIntelligence = () => {
  const [userMessage, setUserMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentAssistantMessage, setCurrentAssistantMessage] = useState('');
  const chatBoxRef = useRef(null);
  const inputRef = useRef(null);
  const [models, setModels] = useState([]);

  const handleInputChange = e => setUserMessage(e.target.value);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await Api.get('/ai/getModels')
        console.log(response)
        setModels(response);
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to fetch models data.');
      }
    };

    // fetchModels();
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [conversation, currentAssistantMessage]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [conversation, loading]);



  // const streamFetch = async e => {
  //   e.preventDefault();

  //   setConversation(prev => [...prev, { role: 'user', content: userMessage }]);
  //   setUserMessage('');
  //   setLoading(true);
  //   setError('');

  //   await fetchAiResponse({
  //     userMessage,
  //     onData: message => setCurrentAssistantMessage(message),
  //     onDone: message => {
  //       setConversation(prev => [
  //         ...prev,
  //         { role: 'assistant', content: message },
  //       ]);
  //       setCurrentAssistantMessage('');
  //       setLoading(false);
  //     },
  //     onError: errorMessage => {
  //       setError(errorMessage);
  //       setLoading(false);
  //     },
  //     progressiveMode: false, // Send the full accumulated message each time
  //   });
  // };



  const streamFetch = async e => {
    e.preventDefault();
  
    setConversation(prev => [...prev, { role: 'user', content: userMessage }]);
    setUserMessage('');
    setLoading(true);
    setError('');
  
    try {
      const response = await Api.post('/ai/askQuestion', {
        userMessage,
        model: 'llama-3.2-3b-instruct',
      });
  
      if (response && response.choices) {
        // Extract the assistant's message from the response
        const assistantMessage = response.choices[0].message.content;
        
        setConversation(prev => [
          ...prev,
          { role: 'assistant', content: assistantMessage },
        ]);
      } else {
        setError('No valid response received from the AI.');
      }
    } catch (error) {
      console.error('Error during AI request:', error);
      setError('An error occurred while fetching the AI response.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChatContainer>
      <h2>Chat with OceanLabs Support</h2>
      <div>
        <h3>Available Models (Raw JSON Response):</h3>
        <pre
          style={{
            // backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          {models.length > 0
            ? JSON.stringify(models, null, 2)
            : 'No models available or failed to fetch.'}
        </pre>
      </div>
      {conversation.length > 0 && (
        <ChatBox ref={chatBoxRef}>
          {conversation.map((message, index) => (
            <ChatMessage key={index} role={message.role}>
              <p>{message.content}</p>
            </ChatMessage>
          ))}
          {currentAssistantMessage && (
            <ChatMessage role='assistant'>
              <p>{currentAssistantMessage}</p>
            </ChatMessage>
          )}
        </ChatBox>
      )}

      <ChatInputForm onSubmit={streamFetch}>
        <Input
          ref={inputRef}
          type='text'
          value={userMessage}
          onChange={handleInputChange}
          placeholder='Type your message...'
          disabled={loading}
          required
        />
        <SendButton type='submit' disabled={loading || !userMessage.trim()}>
          {loading ? 'Loading...' : 'Send'}
        </SendButton>
      </ChatInputForm>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ChatContainer>
  );
};
