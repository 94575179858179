import styled from 'styled-components';

export const ChatContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

export const ChatBox = styled.div`
  max-height: 900px;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
`;

export const ChatMessage = styled.div`
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  background-color: ${props => (props.role === 'user' ? '#f0f0f0' : '#007bff')};
  color: ${props => (props.role === 'user' ? 'black' : 'white')};
  text-align: ${props => (props.role === 'user' ? 'left' : 'right')};
  margin-left: ${props => (props.role === 'user' ? '0' : 'auto')};
`;

export const ChatInputForm = styled.form`
  display: flex;
  gap: 10px;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

export const SendButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;
