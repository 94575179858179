import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuthContext } from '../../../utils';

export const ChatComponent = ({ setMessages, messages, idToCast, focusTrigger, lastLogin, lastMessage }) => {
  const [msgToSend, setMsg] = useState('');
  const user = useAuthContext();
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [focusTrigger]);

  const sendMessage = async () => {
  if (msgToSend.trim() === '') return;

  const newMessage = {
    sender: user?.user?._id,
    receiver: idToCast,
    content: msgToSend,
    timestamp: new Date(), // Add a local timestamp until server time is confirmed
  };

  try {
    // Optimistically add the new message to the messages state
    setMessages(prevMessages => [...prevMessages, newMessage]);

    // Send the message to the server
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/messages/send`,
      newMessage
    );

    // Replace the optimistically added message with the response message if needed
    if (response.data) {
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg === newMessage ? response.data : msg
        )
      );
    }

    // Clear the input field
    setMsg('');
    scrollToBottom();
  } catch (error) {
    console.error('An error occurred:', error);
    // Optionally remove the optimistically added message in case of an error
    setMessages(prevMessages =>
      prevMessages.filter(msg => msg !== newMessage)
    );
  }
};
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div style={styles.chatContainer}>
      {/* Chat Header */}
      <div style={styles.header}>
        <h3 style={styles.title}>Chat</h3>
        <div style={styles.infoContainer}>
          <p style={styles.infoText}>
            Last Login: {lastLogin ? format(new Date(lastLogin), 'MMMM dd, yyyy HH:mm') : 'N/A'}
          </p>
          <p style={styles.infoText}>
            Last Message: {lastMessage ? format(new Date(lastMessage), 'MMMM dd, yyyy HH:mm') : 'N/A'}
          </p>
        </div>
      </div>

      {/* Messages */}
      <div style={styles.messagesContainer}>
        {messages.length > 0 ? (
          messages.map((message) => {
            const isCurrentUser = message.sender === user?.user?._id;
            return (
              <div key={message.id} style={{ ...styles.messageWrapper, textAlign: isCurrentUser ? 'right' : 'left' }}>
                <div style={{ ...styles.messageBubble, backgroundColor: isCurrentUser ? '#DCF8C6' : '#FFF' }}>
                  <strong>{message.senderName}</strong> {message.content}
                  <div style={styles.timestamp}>
                    {format(new Date(message.timestamp), 'HH:mm')}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No messages available</p>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <div style={styles.inputContainer}>
        <input
          ref={inputRef}
          value={msgToSend}
          onChange={(e) => setMsg(e.target.value)}
          onKeyPress={handleKeyPress}
          style={styles.input}
          placeholder="Type your message"
        />
        <button onClick={sendMessage} style={styles.sendButton}>
          Send
        </button>
      </div>
    </div>
  );
};

// Styles
const styles = {
  chatContainer: {
    background: 'lightgrey',
    padding: 16,
    borderRadius: 8,
    flex: 1,
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  title: {
    margin: 0,
  },
  infoContainer: {
    textAlign: 'right',
    color: 'gray',
    fontSize: '0.9em',
  },
  infoText: {
    margin: 0,
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    paddingBottom: '50px',
  },
  messageWrapper: {
    marginBottom: 8,
  },
  messageBubble: {
    display: 'inline-block',
    color: '#1a1a1a',
    padding: '8px 12px',
    borderRadius: '16px',
    maxWidth: '70%',
    textAlign: 'left',
  },
  timestamp: {
    fontSize: '0.8em',
    color: 'gray',
    marginTop: '4px',
  },
  inputContainer: {
    display: 'flex',
    marginTop: '16px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'lightgrey',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  input: {
    width: 'calc(100% - 60px)',
    marginRight: '8px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  sendButton: {
    padding: '8px 16px',
  },
};

export default ChatComponent;