import React from 'react';
import * as S from './styles'; // Import all styled components with `S`

export const UserList = ({ users, selectedUser, setSelectedUser }) => {
  return (
    <S.UserListContainer>
      {users?.map(user => {
        return (
        <S.UserCard
          key={user._id}
          $active={user._id === selectedUser?._id}
          onClick={() => setSelectedUser(user)}
        >
          {/* <S.Avatar src={user.avatar} alt={`${user.firstName} avatar`} /> */}
          
          <S.UserName>
            {user.firstName} {user.lastName}
          </S.UserName>
        </S.UserCard>
      )})}
    </S.UserListContainer>
  );
};
