import styled from 'styled-components';
import { Menu as AntdMenu } from 'antd';

export const Menu = styled(AntdMenu)`
  // overrides Antd Default white color on hover
  color: transparent;
  background: transparent;
  border: 0;
  min-width: min-content;

  &&& li {
    padding: 0;
    margin: 0 4px;

    ${({ mode, theme }) =>
      mode === 'vertical' &&
      `
      border: 1px solid;
      border-color: ${theme.colors.grey80};
      border-top-width: 0;

      :first-of-type {
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
        border-top-width: 1px;
      }

      :last-of-type {
        border-bottom-left-radius: 1px;
        border-bottom-right-radius: 1px;
      }

      i {
        margin-left: 8px;
      }
    `}
  }

  && .ant-menu-item::after {
    content: none;
  }
`;
