import React, { useEffect, useState } from 'react';
import { Spin, Button, Input, Select } from 'antd';
import { useTheme } from 'styled-components';
import { streamFetchAIResponse } from '../../../utils';
import axios from 'axios';

export const CollapsibleSection = ({ location, user, boat, work }) => {
  const [agencies, setAgencies] = useState([]);
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeAgencyId, setActiveAgencyId] = useState(null);
  const [generatedTextMap, setGeneratedTextMap] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [error, setError] = useState('');
  const [subjectMap, setSubjectMap] = useState({});

  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Croatian', label: 'Croatian' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Italian', label: 'Italian' },
  ];

  useEffect(() => {
    fetchAgencies();
  }, [location]);

  const fetchAgencies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAgenciesByLocation`,
        { params: { country: location.country, region: location.region } }
      );
      setVerifiedAgencies(response.data.verifiedAgencies);
      setAgencies(response.data.agencies);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = (agencyId, newSubject) => {
    setSubjectMap(prev => ({ ...prev, [agencyId]: newSubject }));
  };

  const fetchGeneratedText = async (agencyId, agencyName) => {
    setError('');
    setGeneratedTextMap(prev => ({ ...prev, [agencyId]: '' }));

    const boatDetails = [
      boat.type && `- Boat Type: ${boat.type}`,
      boat.length && `- Length: ${boat.length}m`,
      boat.width && `- Width: ${boat.width}m`,
      boat.draught && `- Draught: ${boat.draught}m`,
      boat.brand && `- Brand: ${boat.brand}`,
      boat.model && `- Model: ${boat.model}`,
      boat.year && `- Year of Manufacture: ${boat.year}`,
    ].filter(Boolean).join(', ');

    const workDetails = [
      work.description && `- Work Description: ${work.description}`,
      work.serviceType && `- Service Type: ${work.serviceType}`,
    ].filter(Boolean).join(', ');

    const messageContent = `
      Generate an offer request for the agency "${agencyName}" with the following boat and work details:
      ${boatDetails ? `Boat Details: ${boatDetails}.` : ''}
      ${workDetails ? `Work Details: ${workDetails}.` : ''}
      Request a 5% platform discount given as a discount in the offer.
    `.trim();

    // Use fetchAiResponse to generate text
    await streamFetchAIResponse({
      userMessage: messageContent,
      onData: message => setGeneratedTextMap(prev => ({
        ...prev,
        [agencyId]: (prev[agencyId] || '') + message,
      })),
      onDone: message => {
        setGeneratedTextMap(prev => ({ ...prev, [agencyId]: message }));
        setError('');
      },
      onError: errorMessage => setError(errorMessage),
      progressiveMode: true, // Only send the latest fragment
    });
  };

  const handleAgencyClick = (agencyId) => {
    setActiveAgencyId(activeAgencyId === agencyId ? null : agencyId);
  };

  const handleGenerateClick = (agencyId, agencyName) => {
    fetchGeneratedText(agencyId, agencyName);
  };

  const handleTextChange = (agencyId, newText) => {
    setGeneratedTextMap(prev => ({ ...prev, [agencyId]: newText }));
  };

  const theme = useTheme();

  return (
    <div style={{ marginTop: '16px', padding: '10px', backgroundColor: theme.colors.grey80, borderRadius: '5px' }}>
      <h4>Additional Information</h4>
      <p><strong>User:</strong> {user.firstName} {user.lastName} ({user.email})</p>
      <p><strong>Boat:</strong> {boat.type} ({boat.length}m length, {boat.width}m width, {boat.draught}m draught, {boat.brand}, {boat.model}, {boat.year})</p>
      <p><strong>Work:</strong> {work.title}, {work.serviceType}, {new Date(work.dueDate).toLocaleDateString()}</p>

      <h4>Agencies in this location</h4>

      {loading ? (
        <Spin />
      ) : (
        <div>
          <h5>Verified Agencies:</h5>
          {verifiedAgencies.map(agency => (
            <div key={agency._id} style={{ marginBottom: '8px' }}>
              <Button type="primary" onClick={() => handleAgencyClick(agency._id)}>
                {agency.name}
              </Button>
              {activeAgencyId === agency._id && (
                <div style={{ marginTop: '8px', paddingLeft: '16px' }}>
                  <Select
                    value={selectedLanguage}
                    onChange={value => setSelectedLanguage(value)}
                    style={{ width: '150px', marginBottom: '8px' }}
                    options={languageOptions}
                  />
                  <Button type="default" onClick={() => handleGenerateClick(agency._id, agency.name)} style={{ marginBottom: '8px' }}>
                    Generate
                  </Button>
                  {error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                  ) : (
                    <Input.TextArea
                      value={generatedTextMap[agency._id] || ''}
                      rows={8}
                      onChange={e => handleTextChange(agency._id, e.target.value)}
                      placeholder="Generated offer request will appear here..."
                    />
                  )}
                </div>
              )}
            </div>
          ))}

          <h5>Unverified Agencies:</h5>
          {agencies.map(agency => (
            <div key={agency._id} style={{ marginBottom: '8px' }}>
              <Button type="default" onClick={() => handleAgencyClick(agency._id)}>
                {agency.name}
              </Button>
              {activeAgencyId === agency._id && (
                <div style={{ marginTop: '8px', paddingLeft: '16px' }}>
                  <Select
                    value={selectedLanguage}
                    onChange={value => setSelectedLanguage(value)}
                    style={{ width: '150px', marginBottom: '8px' }}
                    options={languageOptions}
                  />
                  <Button type="default" onClick={() => handleGenerateClick(agency._id, agency.name)} style={{ marginBottom: '8px' }}>
                    Generate
                  </Button>
                  {error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                  ) : (
                    <>
                      <Input
                        value={subjectMap[agency._id] || ''}
                        onChange={e => handleSubjectChange(agency._id, e.target.value)}
                        placeholder="Email Subject"
                        style={{ marginBottom: '8px' }}
                      />
                      <Input.TextArea
                        value={generatedTextMap[agency._id] || ''}
                        rows={8}
                        onChange={e => handleTextChange(agency._id, e.target.value)}
                        placeholder="Generated offer request will appear here..."
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};