/** @format */

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../utils';
import { Button, Input, Switch, Table } from 'antd';
import { Thumbnail } from '../../common';
import { updateAgencyValue } from './updateValue';

const AvatarUrlField = ({ url, agencyId, updateAvatar }) => {
  const [editing, setEditing] = useState(false);
  const [newAvatarUrl, setNewAvatarUrl] = useState('');

  const cancel = () => {
    setEditing(false);
    setNewAvatarUrl('');
  };

  const updateValues = () => {
    if (!agencyId) return;

    updateAvatar({
      agencyId,
      field: 'avatar',
      value: newAvatarUrl,
      oceanLabsRegistered: false,
    });
    setEditing(false);
  };

  if (editing) {
    return (
      <>
        <Input onChange={(val) => setNewAvatarUrl(val.target.value)} />
        <Button onClick={cancel}>cancel</Button>
        <Button onClick={updateValues}>update</Button>
      </>
    );
  }

  return <div onClick={() => setEditing(true)}>{url}</div>;
};

export const AgencyPortal = () => {
  const { token } = useAuthContext();
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [unverifiedAgencies, setUnverifiedAgencies] = useState([]);
  const [loading, setLoading] = useState([]);
  const [searchVerified, setSearchVerified] = useState('');
  const [searchUnverified, setSearchUnverified] = useState('');

  const updateAgencyValue = async ({
    agencyId,
    field,
    value,
    oceanLabsRegistered = false,
  }) => {
    setLoading([...new Set([...loading, agencyId])]);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/updateAgency`,
        { agencyId, field, value }
      );

      if (field) {
        const currentData = (
          oceanLabsRegistered ? verifiedAgencies : unverifiedAgencies
        ).find((agency) => agency._id === agencyId);

        const result = verifiedAgencies.map((item) =>
          item._id === agencyId ? { ...currentData, [field]: value } : item
        );

        const unverifiedAgencyResults = unverifiedAgencies.map((item) => 
          item._id === agencyId ? { ...currentData, [field]: value } : item
        );

        setLoading(loading.filter((x) => x !== agencyId));

        if (oceanLabsRegistered) return setVerifiedAgencies(result);
        else return setUnverifiedAgencies(unverifiedAgencyResults);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const columns = [
    {
      title: 'id',
      dataIndex: '_id',
      key: '_id',
      width: 10,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar) => <Thumbnail src={avatar} alt="logo" />,
    },
    {
      title: 'AvatarUrl',
      dataIndex: 'avatar',
      key: 'avatar',
      width: '10%',
      render: (_, data) => (
        <AvatarUrlField
          updateAvatar={(val) => updateAgencyValue({
            agencyId: data._id,
            field: 'avatar',
            value: val.value,
            oceanLabsRegistered: false,
          })}
          url={data?.avatar}
          agencyId={data._id}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'full',
      render: (address) => <span>{address?.full}</span>,
    },
    {
      title: 'Region',
      dataIndex: 'address',
      key: 'region',
      render: (address) => <span>{address?.region}</span>,
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'country',
      render: (address) => <span>{address?.country}</span>,
    },
    {
      title: 'isListed',
      dataIndex: 'isListed',
      key: 'isListed',
      render: (_, data) => (
        <Switch
          disabled={loading.includes(data._id)}
          checked={data.isListed}
          onChange={(val) =>
            updateAgencyValue({
              agencyId: data._id,
              field: 'isListed',
              value: val,
              oceanLabsRegistered: true,
            })
          }
        />
      ),
    },
  ];

  const unverifiedAgencyColumns = columns.map((col) =>
    col.key === 'isListed'
      ? {
          ...col,
          render: (_, data) => (
            <Switch
              disabled={loading.includes(data._id)}
              checked={data.isListed}
              onChange={(val) =>
                updateAgencyValue({
                  agencyId: data._id,
                  field: 'isListed',
                  value: val,
                  oceanLabsRegistered: false,
                })
              }
            />
          ),
        }
      : col
  );

  const setHeaders = async () => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
  };

  async function getAgencies() {
    await setHeaders();
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getAgencies`);
      setVerifiedAgencies(response.data.verifiedAgencies);
      setUnverifiedAgencies(response.data.agencies);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  useEffect(() => {
    getAgencies();
  }, [token]);

  const handleDataSource = (verifiedDataSource) => {
    return (verifiedDataSource ? verifiedAgencies : unverifiedAgencies).filter(
      (agency) => {
        const { name, email, address } = agency;
        const searchFilter = verifiedDataSource ? searchVerified : searchUnverified;
        return (
          name?.toLowerCase().includes(searchFilter) ||
          email?.toLowerCase().includes(searchFilter) ||
          address?.region?.toLowerCase().includes(searchFilter) ||
          address?.country?.toLowerCase().includes(searchFilter) ||
          address?.locality?.toLowerCase().includes(searchFilter)
        );
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, maxWidth: '100vw' }}>
      <Input
        style={{ width: 400 }}
        title="search"
        onChange={(val) => setSearchVerified(val.target.value.toLowerCase())}
      />
      <div style={{ maxWidth: '100vw', overflowX: 'auto' }}>
        <Table
          rowKey="_id"
          dataSource={handleDataSource(true)}
          columns={columns}
          scroll={{ x: 'max-content' }}
        />
      </div>

      <Input
        style={{ width: 400 }}
        title="search"
        onChange={(val) => setSearchUnverified(val.target.value.toLowerCase())}
      />
      <div style={{ maxWidth: '100vw', overflowX: 'auto' }}>
        <Table
          rowKey="_id"
          dataSource={handleDataSource(false)}
          columns={unverifiedAgencyColumns}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  );
};
