export interface UITheme {
  chart: {
    background: string;
    red: string;
    green: string;
    blue: string;
    grey: string;
    font: { size: number; family: string };
  };
  colors: {
    white: string;
    black: string;
    transparent: string;
    green: string;
    grey10: string;
    grey20: string;
    grey30: string;
    grey40: string;
    grey50: string;
    grey60: string;
    grey70: string;
    grey80: string;
    grey90: string;
    grey100: string;
    blue10: string;
    blue20: string;
    blue30: string;
    blue40: string;
    blue50: string;
    blue60: string;
    blue70: string;
    blue80: string;
    blue90: string;
    blue100: string;
    magenta10: string;
    magenta20: string;
    magenta30: string;
    magenta40: string;
    magenta50: string;
    magenta60: string;
    magenta70: string;
    magenta80: string;
    magenta90: string;
    magenta100: string;
    green10: string;
    green20: string;
    green30: string;
    green40: string;
    green50: string;
    green60: string;
    green70: string;
    green80: string;
    green90: string;
    green100: string;
    red10: string;
    red20: string;
    red30: string;
    red40: string;
    red50: string;
    red60: string;
    red70: string;
    red80: string;
    red90: string;
    red100: string;
    yellow10: string;
    yellow20: string;
    yellow30: string;
    yellow40: string;
    yellow50: string;
    yellow60: string;
    yellow70: string;
    yellow80: string;
    yellow90: string;
    yellow100: string;
    darkBlue: string;
    darkPurple: string;
  };
  spacing: {
    small: number;
    normal: number;
    large: number;
  };
  weights: {
    normal: number;
    semibold: number;
    bold: number;
  };
  heights: {
    navbar: number;
    topMenu: number;
  };
}

export const theme: UITheme = {
  chart: {
    background: '#18141D',
    red: '#F75556',
    green: '#2AA33B',
    blue: '#0064FC',
    grey: '#716F74',
    font: { size: 12, family: 'IBM Plex sans' },
  },
  colors: {
    white: '#fff',
    black: '#000',
    transparent: 'transparent',
    green: '#2AA33B',
    grey10: '#F4F3F4',
    grey20: '#E0DFE0',
    grey30: '#C7C6C8',
    grey40: '#A9A8AA',
    grey50: '#8F8D90',
    grey60: '#716F74',
    grey70: '#545157',
    grey80: '#3B383D',
    grey90: '#28242B',
    grey100: '#18141D',
    blue10: '#F1F3FF',
    blue20: '#D9DEFF',
    blue30: '#B8C3FF',
    blue40: '#8FA3FF',
    blue50: '#6386FF',
    blue60: '#0064FC',
    blue70: '#0049BE',
    blue80: '#003289',
    blue90: '#122352',
    blue100: '#081334',
    magenta10: '#FFF0F5',
    magenta20: '#FFD4E4',
    magenta30: '#FFB0CF',
    magenta40: '#FF7DB8',
    magenta50: '#ED55A0',
    magenta60: '#D60084',
    magenta70: '#A10062',
    magenta80: '#730045',
    magenta90: '#50002E',
    magenta100: '#33001B',
    green10: '#E0FBE2',
    green20: '#99F4A1',
    green30: '#7DDB85',
    green40: '#4ABF57',
    green50: '#2AA33B',
    green60: '#00821F',
    green70: '#006015',
    green80: '#00440B',
    green90: '#002D05',
    green100: '#001B02',
    red10: '#FEF1F1',
    red20: '#FCD7D7',
    red30: '#FAB5B5',
    red40: '#F88888',
    red50: '#F75556',
    red60: '#DA2022',
    red70: '#A41517',
    red80: '#750C0D',
    red90: '#510606',
    red100: '#340203',
    yellow10: '#FFF2E0',
    yellow20: '#FCDEAF',
    yellow30: '#FFDB9D',
    yellow40: '#EFC059',
    yellow50: '#F3BF3F',
    yellow60: '#F7BE00',
    yellow70: '#D2A100',
    yellow80: '#B28800',
    yellow90: '#684F00',
    yellow100: '#493600',
    darkBlue: '#00123D',
    darkPurple: '#3c3741',
  },
  spacing: {
    small: 8,
    normal: 16,
    large: 24,
  },
  weights: {
    normal: 400,
    semibold: 500,
    bold: 600,
  },
  heights: {
    navbar: 85,
    topMenu: 72,
  },
};
