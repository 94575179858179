import styled from 'styled-components';
import { Typography } from 'antd';

const { Title: AntdTitle, Text: AntdText } = Typography;

const mapFontSize = {
  5: 14,
  4: 16,
  3: 20,
  2: 24,
  1: 30,
};

const increaseLineHeightBy = 8;

export const Title = styled(AntdTitle)`
  && {
    line-height: ${({ level = 1 }) => mapFontSize[level] + increaseLineHeightBy}px;
    font-size: ${({ level = 3 }) => mapFontSize[level]}px;
    color: ${({ $color = 'white', theme }) => theme.colors[$color]};
    margin: 0;
    ${({ $transform }) => $transform && `text-transform: ${$transform}`};
    ${({ $align }) => $align && `text-align: ${$align}`};
  }
`;

export const Text = styled(AntdText)`
  font-size: ${({ $size = 16 }) => $size}px;
  color: ${({ $color = 'grey30', theme }) => theme.colors[$color]};
  ${({ $align }) => $align && `text-align: ${$align}`};
`;
