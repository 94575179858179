// src/utils/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './authContext';

const ProtectedRoute = ({ children }) => {
  const { token } = useAuthContext();

  // If there’s no token, redirect to the login page
  if (!token) {
    return <Navigate to="/admin-x9z7q1w3t8" replace />;
  }

  return children;
};

export default ProtectedRoute;