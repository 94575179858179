import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { ToggleSwitch } from '../../common';
import { AgencyCard } from './AgencyCard';
import { ChatComponent } from './ChatComponent';
import { UserList } from './UserList';
import { useAuthContext } from '../../../utils';
import Api from '../../../utils/api';

export const Users = () => {
  const { token } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isUserPage, setIsUserPage] = useState(true);
  const [focusTrigger, setFocusTrigger] = useState(0);
  const socket = useRef(null); // WebSocket reference
  const audioRef = useRef(new Audio('/sounds/notification.mp3')); // Audio reference

  const adminEmail = 'oooytun@icloud.com';

  // Establish WebSocket connection on mount
  useEffect(() => {
    if (token) {
      socket.current = io(process.env.REACT_APP_WS_URL, { query: { token } });

      socket.current.on('connect', () => {
        console.log('Connected to WebSocket', socket.current.id);
      });

      // Listen for new messages and update the messages state
      // Listen for new messages and update the messages state
      // Listen for new messages and update the messages state
      socket.current.on('RECEIVE_MESSAGE', newMessage => {
        audioRef.current.play();

        // Check if the new message is intended for the currently selected user or agency
        const isMessageForSelectedUser =
          (isUserPage && newMessage.receiver === selectedUser?._id) ||
          (!isUserPage && newMessage.receiver === selectedAgency?._id);

        if (isMessageForSelectedUser) {
          setMessages(prevMessages => [...prevMessages, newMessage]);
        }
      });

      socket.current.on('disconnect', reason => {
        console.log('Disconnected from WebSocket:', reason);
      });

      return () => socket.current?.disconnect();
    }
  }, [token]);

  const setHeaders = () => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
  };

  const getUsers = async () => {
    setHeaders();
    try {
      const response = await Api.get('/admin/getUsers');
      const filteredUsers = response.users
        .filter(user => user.email !== adminEmail)
        .sort((a, b) => new Date(b.lastMessage) - new Date(a.lastMessage));

      setUsers(filteredUsers);
      setAgencies(response.agencies);

      if (isUserPage && filteredUsers.length > 0) {
        setSelectedUser(filteredUsers[0]);
      } else if (!isUserPage && response.agencies.length > 0) {
        setSelectedAgency(response.data.agencies[0]);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [token, isUserPage]);

  const handleToggle = () => {
    setIsUserPage(!isUserPage);
    setSelectedUser(null);
    setSelectedAgency(null);
    setMessages([]);

    if (!isUserPage && users.length > 0) {
      setSelectedUser(users[0]);
    } else if (isUserPage && agencies.length > 0) {
      setSelectedAgency(agencies[0]);
    }
  };

  const getMessageHistory = async () => {
    const activeUserId =
      isUserPage && selectedUser ? selectedUser._id : selectedAgency?._id;
    if (!activeUserId) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/messages/getHistory`,
        { params: { userId: activeUserId } }
      );
      setMessages(response.data.messages);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    getMessageHistory();
  }, [selectedUser, selectedAgency]);

  useEffect(() => {
    if (selectedUser || selectedAgency) {
      setFocusTrigger(prev => prev + 1);
    }
  }, [selectedUser, selectedAgency]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight: '100%',
      }}
    >
      <div style={{ alignSelf: 'start' }}>
        <ToggleSwitch checked={isUserPage} onToggle={handleToggle} />
      </div>

      <div style={{ display: 'flex', gap: 8 }}>
        {isUserPage ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              maxHeight: '80vh',
            }}
          >
            <UserList
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {agencies.map(agency => (
              <AgencyCard
                key={agency._id}
                $active={agency._id === selectedAgency?._id}
                name={agency.name}
                surname={agency.authorizedPersonName}
                onClick={() => setSelectedAgency(agency)}
              />
            ))}
          </div>
        )}

        <div style={{ flex: 1 }}>
          <ChatComponent
            idToCast={isUserPage ? selectedUser?._id : selectedAgency?._id}
            messages={messages}
            setMessages={setMessages}
            focusTrigger={focusTrigger}
            lastLogin={selectedUser?.lastLogin}
            lastMessage={selectedUser?.lastMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
