// Fetches ai response in a stream

const oceanlabsDefinition =
  'OceanLabs is a comprehensive platform designed to facilitate connections in the yachting industry. Operating primarily in the Mediterranean region (covering countries like Turkey, Greece, Montenegro, Italy, and Spain), OceanLabs serves as a hub where boat owners or captains can post maintenance and service jobs, allowing them to receive offers from technical service companies. This model streamlines yacht maintenance and management by directly connecting relevant parties. The platform earns revenue through a commission of 10%-15% on each transaction, supporting its operational costs and continued service development.';
export const streamFetchAIResponse = async ({
  userMessage,
  model = 'llama-3.2-3b-instruct',
  onData,
  onDone,
  onError,
  progressiveMode = false,
}) => {
  try {
    if (!process.env.REACT_APP_AI_URL) {
      console.log('AI is offline');
      onError('AI is offline');
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_AI_URL}/v1/chat/completions`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          model,
          messages: [
            {
              role: 'system',
              content: oceanlabsDefinition,
            },
            { role: 'user', content: userMessage },
          ],
          temperature: 0.7,
          max_tokens: -1,
          stream: true,
        }),
      }
    );

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let messageBuffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        onDone(messageBuffer);
        break;
      }

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split('\n').filter(Boolean);

      for (const line of lines) {
        if (line.startsWith('data:')) {
          const jsonString = line.replace(/^data: /, '');

          if (jsonString === '[DONE]') continue;

          try {
            const update = JSON.parse(jsonString);
            if (
              update.choices &&
              update.choices[0].delta &&
              update.choices[0].delta.content
            ) {
              const newFragment = update.choices[0].delta.content;
              messageBuffer += newFragment;

              // Send either the latest fragment or the full message, based on progressiveMode
              onData(progressiveMode ? newFragment : messageBuffer);
            }
          } catch (error) {
            console.error('Error parsing update:', error);
          }
        }
      }
    }
  } catch (error) {
    console.error('Error during stream fetch:', error);
    onError('An error occurred while fetching the AI response.');
  }
};
