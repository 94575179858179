// src/TermsAndCondition.js
import React from 'react';

const TermsAndConditions = () => (
  <div
    className='terms-and-conditions'
    style={{
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      backgroundColor: '#1a1a1a', // Dark background color
      color: '#ffffff', // Light text color for readability
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }}
  >
    <h2 style={{ color: '#ffffff' }}>Terms and Conditions</h2>
    <p style={{ color: '#cccccc' }}>Effective Date: 4 November 2024</p>

    <h3 style={{ color: '#ffffff' }}>1. Purpose of the Services</h3>
    <p style={{ color: '#cccccc' }}>
      OceanLabs is designed to help yacht owners manage their yachts with greater ease. By using OceanLabs, you agree to these Terms and Conditions.
    </p>

    <h3 style={{ color: '#ffffff' }}>2. User Responsibilities</h3>
    <p style={{ color: '#cccccc' }}>
      Users are fully responsible for the data they choose to share with agencies. While OceanLabs encrypts this data and does not access it, responsibility for its content remains solely with the user.
    </p>

    <h3 style={{ color: '#ffffff' }}>3. Validity of Information</h3>
    <p style={{ color: '#cccccc' }}>
      Users must provide accurate and valid information to OceanLabs. Invalid or misleading information is prohibited.
    </p>

    <h3 style={{ color: '#ffffff' }}>4. Prohibited Activities</h3>
    <p style={{ color: '#cccccc' }}>
      OceanLabs strictly forbids any sexual, disrespectful, or harmful activities within its platform. Users are expected to act responsibly and respectfully at all times.
    </p>

    <h3 style={{ color: '#ffffff' }}>5. Intellectual Property</h3>
    <p style={{ color: '#cccccc' }}>
      Data shared with OceanLabs belongs to OceanLabs and may be used to enhance our services. Should we need to share any of this data externally, users will be notified and have three months to reject such sharing.
    </p>

    <h3 style={{ color: '#ffffff' }}>6. Limitation of Liability</h3>
    <p style={{ color: '#cccccc' }}>
      OceanLabs is not liable for any data that is lost, stolen, or otherwise compromised. Users acknowledge that security is a priority, but no transmission method is completely secure.
    </p>

    <h3 style={{ color: '#ffffff' }}>7. Account Termination</h3>
    <p style={{ color: '#cccccc' }}>
      OceanLabs reserves the right to terminate user accounts for violations of these terms, offensive behavior, or prolonged inactivity. All termination decisions are made by OceanLabs administrators and are final.
    </p>

    <h3 style={{ color: '#ffffff' }}>8. Contact Information</h3>
    <p style={{ color: '#cccccc' }}>
      Users can reach out to OceanLabs administrators through the in-app chat or by email at info@oceanlabs.app for any questions regarding these Terms and Conditions.
    </p>
  </div>
);

export default TermsAndConditions;