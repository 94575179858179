import styled, { css } from 'styled-components';
import { Text } from '../Typography';

export const MenuText = styled(Text).attrs({
  $size: 14,
  $color: 'grey30',
})`
  line-height: 1;
`;

const verticalGlow = css`
  &&::before {
    content: '';
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.blue50};
    box-shadow: 0px 1px 5px #0064fc, 0px -1px 4px #597ef7, 0px 1px 3px #3480f3,
      5px 1px 12px #0064fc;
  }
`;

const horizontalGlow = css`
  &&::before {
    content: '';
    height: 1px;
    position: absolute;
    top: calc(100% + 7px);
    left: 0px;
    right: 0px;
    background: ${({ theme }) => theme.colors.blue50};
    box-shadow: 0px 1px 5px #0064fc, 0px -1px 4px #597ef7, 0px 1px 3px #3480f3,
      5px 1px 12px #0064fc;
  }
`;

export const Item = styled.a`
  display: flex;
  gap: 8px;
  padding: 6px 8px;
  align-items: center;
  height: 100%;
  position: relative;

  ${({ mode, $isSelected }) =>
    $isSelected && (mode === 'horizontal' ? horizontalGlow : verticalGlow)}

  :hover {
    ${({ $disableHover, theme }) =>
      !$disableHover
        ? `background: ${theme.colors.grey80}`
        : `
            span {
              color: ${theme.colors.blue50};
            }
          `}
  }
`;
