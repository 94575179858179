import { createGlobalStyle } from 'styled-components';

export const GlobalCss = createGlobalStyle`
  body, .ant-layout {
    background: ${({ theme }) => theme.colors.grey100};
  }
  #__next{
    min-height: 100vh;
  }

 `;
