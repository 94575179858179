import { useMemo } from 'react';
import { MenuItem } from '../MenuItem';
import { Icon } from '../Icon';
import { Menu } from '../Menu';

export * from './sideMenuItems';

export const NavBar = ({ mode = 'horizontal', items, ...props }) => {
  const currentPath = window.location.pathname; // Get the current URL path

  const handleClick = (url) => {
    window.location.href = url; // Handle navigation here
  };

  const navBarItems = useMemo(
    () =>
      items.map((element) => ({
        label: (
          <div onClick={() => handleClick(element.url)}>
            <MenuItem
              mode={mode}
              $isSelected={currentPath === element.url} // Compare current path with item URL
              text={element.text}
              icon={<Icon $size={16} name={element.icon} />}
            />
          </div>
        ),
        key: element.key,
      })),
    [items, mode, currentPath] // Depend on currentPath to re-render when URL changes
  );

  return <Menu mode={mode} items={navBarItems} {...props} />;
};
