/** @format */

import { Row, Col, Input, Button, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../utils/authContext';

const LoginForm = styled.div`
  max-width: 400px;
  margin: auto;
  width: 100%;
`;

export const Login = () => {
  const navigate = useNavigate();
  const { setToken, setUser } = useAuthContext();

  // State for email, password, loading, and error message
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function login() {
    setLoading(true);
    setError(''); // Clear any previous error

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/loginAdmin`,
        {
          email,
          password,
        }
      );

      localStorage.setItem('token', response.data.token);
      setUser(response.data.user);
      setToken(response.data.token);
      navigate('/admin-x9z7q1w3t8/messages');
    } catch (error) {
      console.error('An error occurred:', error);
      setToken(undefined);
      setError('Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <LoginForm>
      <Row justify={'center'} gutter={[24, 24]}>
        {error && (
          <Col span={24}>
            <Alert message={error} type='error' showIcon />
          </Col>
        )}
        <Col span={24}>
          <Input
            placeholder='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Input
            type='password'
            placeholder='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Button type='primary' onClick={login} loading={loading}>
            Login
          </Button>
        </Col>
      </Row>
    </LoginForm>
  );
};
