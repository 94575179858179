import { forwardRef } from 'react';
import * as S from './styles';

export const MenuItem = forwardRef(({ text, icon, url, ...props }, ref) => {
  return (
    <S.Item {...props} ref={ref}>
      {icon}
      <S.MenuText>{text}</S.MenuText>
    </S.Item>
  );
});
