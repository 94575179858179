import React from 'react';
import { Title } from '../Typography';
import * as S from './styles';

const buildBreadCrumbs = (breadCrumb) => {
  if (typeof breadCrumb === 'string') return breadCrumb;

  return breadCrumb.map((word, i) => {
    if (i === breadCrumb.length - 1) {
      return <S.BoldBreadCrumb key={word}>{word}</S.BoldBreadCrumb>;
    }

    return `${word} / `;
  });
};

export const Layout = ({
  headerChildren,
  sideBarChildren,
  children,
  title,
  navbar,
  staticHeight = false,
  hideNavbar,
  hideHeader,
}) => {
  return (
    <S.Layout>
      {headerChildren}

      {!hideHeader && (
        <S.Header>
          <S.TitleWrapper>
            <Title level={2} $transform="capitalize">
              {title && buildBreadCrumbs(title)}
            </Title>
          </S.TitleWrapper>
          {navbar}
        </S.Header>
      )}

      <S.Layout
        $hasPadding
        hasSider={!!sideBarChildren}
        $staticHeight={staticHeight}
        $hideNavbar={hideNavbar}
      >
        {!!sideBarChildren && <S.Sider>{sideBarChildren}</S.Sider>}
        <S.Content>{children}</S.Content>
      </S.Layout>
    </S.Layout>
  );
};
