
export const countries = [
  {
    text: 'Tr',
    value: 'tr',
    code: 'tr',
    cities: [ 'aydın', 'izmir', 'antalya', 'istanbul', 'muğla' ]
  },
  {
    text: 'Me',
    value: 'me',
    code: 'me',
    cities: [ 'bar', 'herceg novi', 'budva', 'kotor', 'tivat' ]
  },
  {
    text: 'Hr',
    value: 'hr',
    code: 'hr',
    cities: [
      'makarska',  'zadar',
      'split',     'rijeka',
      'dubrovnik', 'korčula',
      'pula',      'šibenik',
      'senj'
    ]
  },
  {
    text: 'Fr',
    value: 'fr',
    code: 'fr',
    cities: [
      'ajaccio',   'antibes',
      'bordeaux',  'toulon',
      'bastia',    'bonifacio',
      'nice',      'brest',
      'dunkerque', 'calvi',
      'cannes',    'sète',
      'marseille', 'cherbourg',
      'le havre',  'la rochelle',
      'lorient',   'monaco',
      'perpignan'
    ]
  },
  {
    text: 'Gr',
    value: 'gr',
    code: 'gr',
    cities: [
      'athens',       'cyclades',
      'crete',        'ionian islands',
      'corinth',      'peloponnese',
      'thessaloniki', 'kavala',
      'dodecanese',   'sporades',
      'volos',        'alexandroupoli',
      'patras'
    ]
  },
  {
    text: 'It',
    value: 'it',
    code: 'it',
    cities: [
      'amalfi',    'bari',
      'cagliari',  'catania',
      'venice',    'rome',
      'genoa',     'naples',
      'trieste',   'olbia',
      'la spezia', 'messina',
      'palermo',   'livorno',
      'syracuse'
    ]
  },
  {
    text: 'Pt',
    value: 'pt',
    code: 'pt',
    cities: [
      'algarve', 'azores',
      'porto',   'lisbon',
      'madeira', 'leiria',
      'setúbal', 'alentejo'
    ]
  },
  {
    text: 'Es',
    value: 'es',
    code: 'es',
    cities: [
      'a coruña',         'valencia',
      'asturias',         'barcelona',
      'malaga',           'cadiz',
      'murcia',           'fuerteventura',
      'bilbao',           'san sebastián',
      'balearic islands', 'gran canaria',
      'pontevedra',       'vigo',
      'lanzarote',        'tenerife'
    ]
  }
]

export default countries