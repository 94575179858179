import React from 'react';
import { Switch } from 'antd';

export const ToggleSwitch = ({ checked, onToggle, noLabel }) => {
  const handleToggle = checked => {
    onToggle(checked);
  };

  return (
    <div style={{marginBottom: 8}}>
      <Switch checked={checked} onChange={handleToggle} />
      {!noLabel && <p>{checked ? 'Users' : 'Agencies'}</p>}
    </div>
  );
};
