// src/LandingPage.js
import React from 'react';
import './index.css';
import logo from './assets/images/oceanlabs-logo.png';
import { Link } from 'react-router-dom';

const LandingPage = () => (
  <div className='landing-page'>
    <div className='content'>
      <div className='app-info'>
        <div className='app-logo'>
          <img src={logo} alt='Ocean Labs Logo' />
        </div>
        <h1>Ocean Labs</h1>
        <p>Your agency in the cloud.</p>
        {/* <div className='download-buttons'> */}
          <div className='button button-disabled ios'></div>
          {/* <div className='button button-disabled playstore'></div> */}
        {/* </div> */}
        <p className='legal'>
          <Link target='_blank' to='/privacy-policy' style={{ color: 'white' }}>
            Privacy Policy
          </Link>
        </p>
        <p className='legal'>
          <Link target='_blank' to='/privacy-policy' style={{ color: 'white' }}>
            Terms and Conditions
          </Link>
        </p>
        <p className='website'>www.oceanlabs.app</p>
      </div>
      <div className='phone-display'>
        <img
          src={require('./assets/images/app-screenshot.png')}
          alt='App Screenshot'
        />
        <img
          src={require('./assets/images/app-screenshot.png')}
          alt='App Screenshot'
          className='blurred'
        />
      </div>
    </div>
  </div>
);

export default LandingPage;
