import * as S from './styles';
import { Layout } from '../Layout';
import { NavBar } from '../NavBar';

export const PageLayout = ({
  sideBarItems,
  sideBarActions,
  title,
  children,
  hideNavbar,
  hideHeader,
  staticHeight = false,
}) => {
  const dashboardMenuItems = [
    {
      text: 'Bookings',
      icon: 'Beenhere',
      url: '/bookings/summary',
      key: 'bookings',
    },
    {
      text: 'Applications',
      icon: 'Apps',
      url: '/applications/summary',
      key: 'applications',
    },
    { text: 'Terms', icon: 'Gavel', url: '/terms/overview', key: 'terms' },
    {
      text: 'Applicants',
      icon: 'Group',
      url: '/applicants/overview',
      key: 'applicants',
    },
    {
      text: 'Vehicles',
      icon: 'Directions_Car',
      url: '/vehicles/overview',
      key: 'vehicles',
    },
    {
      text: 'Locations',
      icon: 'Public',
      url: '/locations',
      key: 'locations',
    },
  ];

  const sidebar = ({ hasBackButton, hasPortfolioSelect }) => {
    return (
      sideBarItems && (
        <>
          <NavBar items={sideBarItems} mode='vertical' />
          {sideBarActions && (
            <S.SideBarActions>{sideBarActions}</S.SideBarActions>
          )}
        </>
      )
    );
  };

  return (
    <Layout
      staticHeight={staticHeight}
      hideNavbar={hideNavbar}
      hideHeader={hideHeader}
      headerChildren={<></>}
      navbar={
        !hideNavbar && <NavBar mode='horizontal' items={dashboardMenuItems} />
      }
      title={title}
      sideBarChildren={sidebar({
        hasBackButton: true,
        hasPortfolioSelect: true,
      })}
    >
      {children}
    </Layout>
  );
};
