import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const Dropdown = ({
  options,
  placeholder,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: 120 }} // Set width to 120px as requested
      disabled={disabled}
    >
      {options.map((option, index) => (
        <Option key={index} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};
