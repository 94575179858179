import React, { useState, useEffect } from 'react';
import axios from 'axios';
import countries from './countries'; // Assuming countries data is in this file
import { Dropdown } from '../../common'; // Assuming this is the updated Dropdown component
import { setHeaders } from '../../../utils/setHeaders';
import { useAuthContext } from '../../../utils';
import { WorkCard } from './WorkCard'; // Import the reusable WorkCard component
import Api from '../../../utils/api';

export const OfferPortal = () => {
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [cities, setCities] = useState([]); // Initialize as an empty array
  const [selectedCity, setSelectedCity] = useState('All');
  const [works, setWorks] = useState([]); // Initialize as an empty array for works
  const { token } = useAuthContext();

  // Initialize cities with all cities from all countries
  useEffect(() => {
    const allCities = countries.reduce((acc, country) => {
      return [...acc, ...country.cities];
    }, []);
    setCities(['All', ...allCities]);
  }, []);

  const handleCountryChange = value => {
    setSelectedCountry(value);

    if (value === 'All') {
      const allCities = countries.reduce((acc, country) => {
        return [...acc, ...country.cities];
      }, []);
      setCities(['All', ...allCities]);
    } else {
      const country = countries.find(c => c.value === value);
      setCities(country ? ['All', ...country.cities] : []);
    }
    setSelectedCity('All'); // Reset city to 'All' when country changes
  };

  const handleCityChange = value => {
    setSelectedCity(value);
  };

  // Function to send request to getWorks endpoint
  const fetchWorks = async () => {
    setHeaders({ token });
    const params = {};
    if (selectedCountry !== 'All') params.country = selectedCountry;
    if (selectedCity !== 'All') params.region = selectedCity;

    try {
      const response = await Api.get('/admin/getWorks', params);
      setWorks(response.works || []);
    } catch (error) {
      console.error('An error occurred while fetching works:', error);
    }
  };

  // Fetch works when country or city changes
  useEffect(() => {
    fetchWorks();
  }, [selectedCountry, selectedCity]);

  return (
    <div>
      <h3>Offers by Country</h3>

      {/* Country Dropdown */}
      <Dropdown
        options={['All', ...countries.map(country => country.value)]}
        placeholder='Select a country'
        value={selectedCountry}
        onChange={handleCountryChange}
      />

      {/* City Dropdown */}
      <Dropdown
        options={cities}
        placeholder='Select a city'
        value={selectedCity}
        onChange={handleCityChange}
        disabled={selectedCountry === 'All'}
      />

      <div>
        <h4>Works</h4>
        {works.length > 0 ? (
          works.map(work => <WorkCard key={work._id} work={work} />)
        ) : (
          <p>No works available</p>
        )}
      </div>
    </div>
  );
};

export default OfferPortal;
