import { Col, Row, Select } from 'antd';
import styled from 'styled-components';
import { Icon } from '../Icon';

export const BackButtonWrapper = styled.div`
  margin: 0 4px;
  margin-bottom: 24px;
  border: 1px solid #3b383d;
  padding: 6px 0px;

  &:hover {
    background: ${({ theme }) => theme.colors.grey80};
    cursor: pointer;
  }

  & button {
    gap: 4px;
  }
`;

export const DropdownSelectWrapper = styled.div`
  margin: 0 4px;
  margin-bottom: 24px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `solid 1px ${theme.colors.grey60}`};

  .ant-segmented {
    border: ${({ theme }) => `solid 1px ${theme.colors.grey60}`};
  }

  .ant-segmented-item {
    width: 50%;
  }

  & > button {
    height: 34px;
    justify-content: space-between;
    width: fill-available;
    background-color: ${({ theme }) => theme.colors.grey90};
  }

  &&& .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
    padding: 2px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled(Row)`
  &&& .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
    padding: 2px;
  }
  width: 100%;
`;

export const SelectWrapper = styled(Select)`
  width: fill-available;
  align-self: flex-end;

  && .ant-select-selector {
    border: ${({ theme }) => `1px solid ${theme.colors.grey60}`};
    font-size: 14px;
  }

  & .ant-select-selection-item {
    font-size: 14px;
  }

  & .ant-select-selection-placeholder {
    font-size: 14px;
  }
`;

export const DropdownWrapper = styled.div`
  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${({ theme }) => theme.colors.blue80};
  }
`;

export const ClearIcon = styled(Icon)`
  background: ${({ theme }) => theme.colors.grey30};
  border-radius: 50%;
`;

export const InputWrapper = styled(Col)`
  padding: 8px;
`;

export const SideBarActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 4px;
`;
