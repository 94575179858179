/** @format */

import styled from 'styled-components';

export const Thumbnail = styled.img`
  height: 30px;
  width: 30px;
`;

export const Empty = styled.div`
  height: 30px;
  width: 30px;
  background: black;
  opacity: 0.5;
`;
