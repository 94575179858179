import * as S from './styles';

export const AgencyCard = ({
  name,
  surname,
  unread,
  $active = false,
  ...props
}) => {
  return (
    <S.UserCard $active={$active} {...props}>{`${name} ${surname}`}</S.UserCard>
  );
};
