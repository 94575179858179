import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';

const {
  Header: AntdHeader,
  Sider: AntdSider,
  Content: AntdContent,
} = AntdLayout;

const LEFT_SPACING = 16;

export const Layout = styled(AntdLayout)`
  background-color: transparent;
  /* ${({ $hasPadding }) => $hasPadding && `padding-top: 8px;`}; */
  ${({ theme, $staticHeight, $hideNavbar }) =>
    $staticHeight &&
    `height: calc(100vh - ${
      !$hideNavbar
        ? theme.heights.topMenu + theme.heights.navbar
        : theme.heights.navbar
    }px)`}
`;

export const Header = styled(AntdHeader)`
  /* padding-left: ${LEFT_SPACING}px; */
  padding-top: 24px;
  padding-bottom: 8px;
  height: unset;
  line-height: normal;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey80};
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Sider = styled(AntdSider)`
  & {
    flex: unset !important;
    max-width: fit-content !important;
    min-width: unset !important;
    /* width: 20% !important; */
  }
  @media print {
    * {
      visibility: hidden;
    }
  }

  &&& {
    background: transparent;
    /* padding-left: ${LEFT_SPACING}px; */
    width: unset;
    max-width: unset;

    position: sticky;
    top: 24px;
    overflow: auto;
  }
`;

export const Content = styled(AntdContent)`
  /* padding: 0 ${({ theme }) => `${theme.spacing.large}px`}; */
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  height: fit-content;
  height: auto;
  width: 100%;
`;

export const Spacing = styled.div`
  display: flex;
  height: 40px;
`;

export const BoldBreadCrumb = styled.span`
  font-weight: 600;
`;
