/** @format */

import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({
  user: {},
  token: '',
  isAuthenticated: false,
  setUser: () => {},
  setToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(token));

  useEffect(() => {
    // Set headers when the token changes
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      localStorage.setItem('token', token);
    } else {
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('token');
      setIsAuthenticated(false); // Reset authentication state if token is missing
    }
  }, [token]);

  useEffect(() => {
    const loginSilently = async () => {
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/loginWithToken`,
            { token }
          );
          setUser(response.data.user);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Silent login failed:", error);
          setToken(''); // Clear token if login fails
          setIsAuthenticated(false);
          navigate('/admin-x9z7q1w3t8'); // Redirect to login if silent login fails
        }
      }
    };

    // Only attempt silent login if a token is present
    if (token) {
      loginSilently();
    }
  }, [token, navigate]);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);