// src/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => (
  <div
    className='privacy-policy'
    style={{
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      backgroundColor: '#1a1a1a', // Dark background color
      color: '#ffffff', // Light text color for readability
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }}
  >
    <h2 style={{ color: '#ffffff' }}>Privacy Policy</h2>
    <p style={{ color: '#cccccc' }}>Last Updated: 4 November 2024</p>

    <h3 style={{ color: '#ffffff' }}>1. Introduction</h3>
    <p style={{ color: '#cccccc' }}>
      Welcome to Ocean Labs! Your privacy is important to us. This Privacy
      Policy explains how we collect, use, and protect your information when you
      use our services. By using our app, you agree to the terms outlined in
      this policy.
    </p>

    <h3 style={{ color: '#ffffff' }}>2. Information We Collect</h3>
    <h4 style={{ color: '#ffffff' }}>Personal Information</h4>
    <p style={{ color: '#cccccc' }}>
      We may collect personal information that you provide to us, such as your
      name, email address, phone number, and any other information you choose to
      share when you sign up or contact us.
    </p>

    <h4 style={{ color: '#ffffff' }}>Usage Data</h4>
    <p style={{ color: '#cccccc' }}>
      We automatically collect information about your interactions with our app,
      including the pages you view, the time and date of your visit, and the
      actions you take. This data helps us improve our services.
    </p>

    <h4 style={{ color: '#ffffff' }}>Device Information</h4>
    <p style={{ color: '#cccccc' }}>
      We may collect information about the device you use to access our app,
      including device type, operating system, and unique device identifiers.
    </p>

    <h3 style={{ color: '#ffffff' }}>3. How We Use Your Information</h3>
    <p style={{ color: '#cccccc' }}>
      The information we collect is used for various purposes, including:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>To provide and maintain our services</li>
      <li style={{ color: '#cccccc' }}>
        To notify you about changes to our services
      </li>
      <li style={{ color: '#cccccc' }}>To provide customer support</li>
      <li style={{ color: '#cccccc' }}>
        To improve our app and personalize your experience
      </li>
      <li style={{ color: '#cccccc' }}>To monitor usage and analyze trends</li>
      <li style={{ color: '#cccccc' }}>To comply with legal obligations</li>
    </ul>

    <h3 style={{ color: '#ffffff' }}>4. Sharing of Information</h3>
    <p style={{ color: '#cccccc' }}>
      We may share your information with third parties under certain
      circumstances, such as:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>
        With service providers who help us operate our business and app
      </li>
      <li style={{ color: '#cccccc' }}>
        When required by law or to protect our rights and safety
      </li>
      <li style={{ color: '#cccccc' }}>
        In connection with a business transfer, such as a merger or acquisition
      </li>
    </ul>

    <h3 style={{ color: '#ffffff' }}>5. Data Security</h3>
    <p style={{ color: '#cccccc' }}>
      We take data security seriously and use reasonable measures to protect
      your information from unauthorized access, alteration, or destruction.
      However, please be aware that no internet transmission is completely
      secure.
    </p>

    <h3 style={{ color: '#ffffff' }}>6. Data Retention</h3>
    <p style={{ color: '#cccccc' }}>
      We will retain your personal information only for as long as is necessary
      for the purposes set out in this Privacy Policy or as required by law.
    </p>

    <h3 style={{ color: '#ffffff' }}>7. Your Choices and Rights</h3>
    <p style={{ color: '#cccccc' }}>
      You have the right to access, correct, or delete your personal
      information. You can also object to or restrict certain types of data
      processing. To exercise these rights, please contact us at the email
      address below.
    </p>

    <h3 style={{ color: '#ffffff' }}>8. Children's Privacy</h3>
    <p style={{ color: '#cccccc' }}>
      Our app is not intended for children under 13, and we do not knowingly
      collect personal information from children under 13. If we discover that
      we have collected information from a child under 13, we will delete it
      promptly.
    </p>

    <h3 style={{ color: '#ffffff' }}>9. Changes to This Privacy Policy</h3>
    <p style={{ color: '#cccccc' }}>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new policy on this page. You are advised to
      review this policy periodically for any changes.
    </p>

    <h3 style={{ color: '#ffffff' }}>10. Contact Us</h3>
    <p style={{ color: '#cccccc' }}>
      If you have any questions about this Privacy Policy, please contact us:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>Email: info@oceanlabs.app</li>
      <li style={{ color: '#cccccc' }}>Address: Ocean Labs, Montenegro</li>
    </ul>
  </div>
);

export default PrivacyPolicy;
